import axios from 'axios';

export default function loadMorePressReleases() {
    let count = 1;
    const pressReleasesWrapper = document.querySelector('.js-press-release-wrapper');
    if (!pressReleasesWrapper) {
        return;
    }
    const loadReleases = pressReleasesWrapper.querySelector('.js-load-press-releases');
    const releasesList = pressReleasesWrapper.querySelector('.js-press-release-list');

    function runLoader() {
        count += 1;
        const ajaxurl = '/wp-admin/admin-ajax.php';
        const data = new URLSearchParams({
            action: 'get_ajax_posts',
            get_page: count,
        });

        axios.post(ajaxurl, data)
            .then((response) => {
                if (response.data) {
                    releasesList.insertAdjacentHTML('beforeend', response.data);
                }

                if (response.headers['content-length'] !== response.data) {
                    loadReleases.classList.add('is-hidden');
                }
            });
    }

    loadReleases.addEventListener('click', runLoader, false);
}
