export default () => {
    const testimonialSlider = document.querySelectorAll('.js-testimonial-slider');

    if (!testimonialSlider.length || !window.Swiper) {
        return;
    }

    testimonialSlider.forEach((sliderElement) => {
        // eslint-disable-next-line no-unused-vars,no-undef
        const swiper = new Swiper(sliderElement, {
            watchSlidesProgress: true,
            keyboardControl: true,
            watchOverflow: true,
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 16,
            breakpoints: {
                576: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                },
                992: {
                    spaceBetween: 40,
                    slidesPerView: 3,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 60,
                },
            },
        });
    });
};
