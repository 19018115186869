/**
 * Stellt Funktionen zum durchlaufen des DOM zur Verfügung.
 */

/**
 * Sucht nach einem Sibling von "element"
 * @param element {Node|Element}
 * @param query {String} Query-Parameter
 * @returns {Node|null}
 */
export function getSibling(element, query) {
    const parent = element.parentNode;
    return parent.querySelector(query);
}

/**
 * Durchsucht das DOM aufwärts ab "baseElement nach einem Elternelement mit "className".
 * @param baseElement {Node|Element} Das Element von dem aus gesucht werden soll.
 * @param className {string} Klassenname des Elternelement
 * @returns {Node|null} Gibt entweder das gesuchte Elternelement oder null zurück.
 */
export function getParentElementByClassName(baseElement, className) {
    let element = baseElement;

    for (; element && element !== document; element = element.parentNode) {
        const isParentListItem = [].includes.call(element.classList, className);
        if (isParentListItem) return element;
    }

    return null;
}

/**
 * Durchsucht das DOM aufwärts ab "baseElement nach einem Elternelement mit spezifischem Tag-namen".
 * @param baseElement {Node|Element} Das Element von dem aus gesucht werden soll.
 * @param tagName {string} Tagname des Elternelement
 * @returns {Element|null} Gibt entweder das gesuchte Elternelement oder null zurück.
 */
export function getParentElementByTagName(baseElement, tagName) {
    let element = baseElement;

    for (; element && element !== document; element = element.parentNode) {
        if (element.tagName.toUpperCase() === tagName.toUpperCase()) return element;
    }

    return null;
}

/**
 * Versteckt ein Element
 * @param element
 */
export function hideElement(element) {
    if (element.classList.contains('active')) {
        element.classList.remove('active');
    }
}

/**
 * Zeigt ein Element an
 * @param element {HTMLElement|Node}
 */
export function showElement(element) {
    element.classList.add('active');
}

/**
 * Toggelt ein Element
 * @param element
 */
export function toggleElement(element) {
    if (element.classList.contains('active')) {
        return hideElement(element);
    }

    return showElement(element);
}

/**
 * Fügt ein Element hinter einem anderen ein
 * @param element
 * @param newElement
 */
export function insertAfter(element, newElement) {
    if (element.nextSibling) {
        element.parentNode.insertBefore(newElement, element.nextSibling);
    } else {
        element.parentNode.appendChild(newElement);
    }
}
