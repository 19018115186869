export default function dotsDeco() {
    const dots = document.querySelectorAll('.js-dots-deco');

    if (!dots.length) {
        return;
    }

    function random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    dots.forEach((deco) => {
        const style = window.getComputedStyle(deco);
        if (style.display === 'none') {
            return;
        }

        const circles = deco.querySelectorAll('circle');

        setInterval(() => {
            const index = random(1, circles.length);
            const child = deco.querySelector(`circle:nth-child(${index})`);

            if (child) {
                child.classList.add('active');
                setTimeout(() => {
                    child.classList.remove('active');
                }, 2000);
            }
        }, 300);
    });
}
