export default function accordion() {
    const accordions = document.querySelectorAll('.js-accordion');

    accordions.forEach((accordionContainer) => {
        const accordionItems = accordionContainer.querySelectorAll('details');
        accordionItems.forEach((accordionItem) => {
            accordionItem.addEventListener('click', (event) => {
                const active = accordionContainer.querySelector('details[open]');
                if (!event.currentTarget.open && active) {
                    active.open = false;
                }
            });
        });
    });
}
