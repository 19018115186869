class FormService {
    /**
     * Leert die Input-Felder innerhalb eines DIVs.
     * @param form {Node|Element} Das Formular in dem nach den Feldern gesucht wird.
     */
    static clearForm(form) {
        const formElements = FormService.getFormElements(form);

        if (!formElements.length) return;

        formElements.forEach((formElement) => {
            const element = formElement;
            const fieldType = element.getAttribute('type') || element.tagName.toLowerCase();

            switch (fieldType) {
                case 'text':
                case 'email':
                case 'tel':
                case 'date':
                case 'time':
                case 'file':
                case 'textarea':
                    element.value = '';
                    break;
                case 'radio':
                case 'checkbox':
                    if (element.checked) {
                        element.checked = false;
                    }
                    break;
                case 'select-one':
                case 'select-multi':
                    element.selectedIndex = -1;
                    break;
                default:
                    break;
            }
        });
    }

    static toggleFormState(form, disable = false) {
        form.forEach((element) => {
            const formElement = element;
            formElement.readonly = disable;
        });
    }
}

const formService = {
    clearForm(form) {
        return FormService.clearForm(form);
    },
    toggleFormState(form, disable) {
        return FormService.toggleFormState(form, disable);
    },
};

export default formService;
