import copyUrlInClipboard from './modules/copyUrlInClipboard';
import handleHeader from './modules/handleHeader';
import loadMorePressReleases from './modules/loadMorePressReleases';
import openVideoOverlay from './modules/openVideoOverlay';
import slider from './modules/slider';
import testimonialSlider from './modules/testimonialSlider';
import logoSlider from './modules/logoSlider';
import decoBanner from './modules/decoBanner';
import dotsDeco from './modules/dotsDeco';
import accordion from './modules/accordion';
import team from './modules/team';
import underline from './modules/underline';
import poolieForm from './modules/poolieForm';
import roiRangeSlider from './modules/roiRangeSlider';

window.addEventListener('load', copyUrlInClipboard);
window.addEventListener('load', handleHeader);
window.addEventListener('load', loadMorePressReleases);
window.addEventListener('load', openVideoOverlay);
window.addEventListener('load', slider);
window.addEventListener('load', testimonialSlider);
window.addEventListener('load', logoSlider);
window.addEventListener('load', decoBanner);
window.addEventListener('load', dotsDeco);
window.addEventListener('load', accordion);
window.addEventListener('load', team);
window.addEventListener('load', underline);
window.addEventListener('load', poolieForm);
window.addEventListener('load', roiRangeSlider);
