export default function underline() {
    const underlinedWords = document.querySelectorAll('.js-underlined-word');

    underlinedWords.forEach((underlinedWord) => {
        /* eslint-disable max-len */
        const svg = underlinedWord.classList.contains('is-highlighted') ? (
            `<svg class="underline-svg" preserveAspectRatio="none" viewBox="0 0 107 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="underline-path" d="M28 32C38.5803 32 49.3366 32.5872 59.8889 31.8889C64.6902 31.5712 69.6194 30.7818 74.3889 30.1111C75.3945 29.9697 77.5355 29.929 78 29" 
                    stroke="#5fd362" stroke-width="56" stroke-linecap="round"/>
             </svg>`
        ) : (
            `<svg class="underline-svg" preserveAspectRatio="none" viewBox="0 0 307 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="underline-path" d="M4.5 17.9963C117 -3.99999 303 2.99957 303 17.9963" 
                    stroke="#5fd362" stroke-width="12" stroke-linecap="round"/>
             </svg>`
        );

        underlinedWord.insertAdjacentHTML('afterbegin', svg);
    });
}
