import rangeSlider from './rangeSlider';

export default () => {
    const roiCalculators = document.querySelectorAll('.js-roi-calculator');
    roiCalculators.forEach((roiCalculator) => {
        const roiSliderWrapper = roiCalculator.querySelector('.js-roi-slider');

        rangeSlider(roiSliderWrapper, (value) => {
            const inputValue = value;

            const valueElements = roiCalculator.querySelectorAll('.js-roi-calculator-value');
            valueElements.forEach((element) => {
                const valueElement = element;
                const {factor} = valueElement.dataset;
                const result = Math.round(inputValue * factor);

                const resultText = result.toLocaleString('de-DE');
                valueElement.innerHTML = `${resultText}`;

                const valueBadgeElement = roiSliderWrapper.querySelector('.js-roi-slider-value-badge');
                valueBadgeElement.innerHTML = inputValue;
            });
        });
    });
};
