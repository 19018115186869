import * as Pristine from 'pristinejs/dist/pristine';

/**
 * @var {object} DOCUMENT_MIME_TYPES Keys: Mime-typ, Values: menschenlesbare Ausgabe
 */
const DOCUMENT_MIME_TYPES = Object.freeze({
    'application/pdf': 'PDF',
    'image/png': 'PNG',
    'image/jpeg': 'JPEG',
    'image/tiff': 'TIFF',
});

class ValidatorService {
    /**
     * @function Pristine.addValidator
     */
    constructor(form) {
        this.form = form;

        Pristine.addValidator('time-format', (value) => {
            const regex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])?$/; // Matches 00:00 - 23:59
            return !(value !== '' && !value.match(regex));
        }, 1);

        Pristine.addValidator('file-size', function validator(files, maxFileSize) {
            if (!this.files.length) {
                return true;
            }

            return ValidatorService.validateFileSize(this, maxFileSize);
        }, 1);

        Pristine.addValidator('file-mime-type', function validator(value, ...mimeTypes) {
            return ValidatorService.validateFileMimeType(this, mimeTypes);
        }, ValidatorService.getMessage('file-mime-type', this), 2);

        this.pristine = new Pristine(this.form);
    }

    /**
     * commaSeperatedList
     * @param {Array} list Liste als Array
     * @returns {string} Komma-getrennte Liste als String
     */
    static commaSeperatedList(list) {
        return list.reduce((result, current, index) => ((index === 0) ? current : `${result}, ${current}`), '');
    }

    /**
     * fileSizeForHumans
     * @param {int} size Dateigröße in Bytes
     * @returns {string} Dateigröße in menschenlesbarer Form
     */
    static fileSizeForHumans(size) {
        const i = Math.floor(Math.log(size) / Math.log(1000));
        const value = (size / (1000 ** i)).toFixed(2);
        const suffix = ['B', 'kB', 'MB', 'GB'][i];

        return `${value} ${suffix}`;
    }

    /**
     * validateFileSize
     * @param {Element|Node} element - Input-Element
     * @param {string} maxFileSize - Maximale Dateigröße aus Dataset
     * @returns {boolean} Ist Datei valide: true/false
     */
    static validateFileSize(element, maxFileSize) {
        if (typeof element.dataset.pristineRequired !== 'undefined' || element.files.length > 0) {
            const file = element.files[0];

            return file.size <= maxFileSize;
        }

        return true;
    }

    /**
     * validateFileMimeType
     * @param {Element|Node} element - Input-Element
     * @param {array} mimeTypes - Beinhaltet alle erlaubten Dateiformate
     * @returns {boolean} Ist Datei valide: true/false
     */
    static validateFileMimeType(element, mimeTypes) {
        if (typeof element.dataset.pristineRequired !== 'undefined' || element.files.length > 0) {
            const file = element.files[0];
            if (!file || !file.type) return false;

            let typeIndex = 0;
            const typesList = mimeTypes.map((type) => {
                typeIndex = Object.values(DOCUMENT_MIME_TYPES).indexOf(type.trim().toUpperCase());

                return Object.keys(DOCUMENT_MIME_TYPES)[typeIndex];
            });

            return typesList.includes(file.type);
        }

        return true;
    }

    static getMessage(validator, service) {
        const filterByDataset = (input) => Object.keys(input.dataset).includes('poolieFileMimeTypeMessage');
        const [...formElements] = service.form;
        const input = formElements.find(filterByDataset);

        if (!input) return '';

        if (validator === 'file-mime-type') {
            const mimeTypes = input.dataset.pristineFileMimeType.toUpperCase();

            return input.dataset.poolieFileMimeTypeMessage.replace(/\{0\}/gi, mimeTypes);
        }

        return '';
    }

    validateForm(input = null) {
        const valid = this.pristine.validate(input);

        return (!valid) ? this.pristine.getErrors(input) : valid;
    }

    destroy() {
        this.pristine.destroy();
    }
}

const validatorService = {
    init(form) {
        this.service = new ValidatorService(form);

        return this.service;
    },
    destroy() {
        return this.service.destroy();
    },
    validateForm(input) {
        return this.service.validateForm(input);
    },
};

export default validatorService;
