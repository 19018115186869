export default function decoBanner() {
    const banner = document.querySelector('.js-deco-banner');

    if (!banner) {
        return;
    }

    function random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const dots = banner.querySelectorAll('span');

    setInterval(() => {
        const index = random(1, dots.length);
        const child = banner.querySelector(`span:nth-child(${index})`);

        child.classList.add('active');
        setTimeout(() => {
            child.classList.remove('active');
        }, 2000);
    }, 300);
}
