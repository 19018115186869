function changeInputValue(input, thumb, slide, maxLeft, callback) {
    const inputElement = input;
    const thumbLeft = parseInt(thumb.style.left, 10);

    const leftInPercentage = thumbLeft / maxLeft;
    inputElement.value = (inputElement.max * leftInPercentage.toFixed(2));

    const slideElement = slide;
    slideElement.style.width = `${thumbLeft + thumb.offsetWidth + 4}px`;

    callback(inputElement.value);
    const event = new Event('change');
    inputElement.dispatchEvent(event);
}

export default function rangeSlider(sliderElement, callback) {
    const input = sliderElement.querySelector('.js-range-slider-input');
    const slide = sliderElement.querySelector('.js-range-slider-slide');
    const thumb = sliderElement.querySelector('.js-range-slider-thumb');

    let maxLeft = sliderElement.offsetWidth - thumb.offsetWidth;
    window.addEventListener('resize', () => {
        maxLeft = sliderElement.offsetWidth - thumb.offsetWidth;
        changeInputValue(input, thumb, slide, maxLeft, callback);
    }, true);

    let mousePosition;
    let isMouseDown = false;

    const valueInPercentage = input.value / input.max;
    thumb.style.left = `${maxLeft * valueInPercentage}px`;
    changeInputValue(input, thumb, slide, maxLeft, callback);

    'mousedown touchstart'.split(' ').forEach((eventName) => {
        sliderElement.addEventListener(eventName, () => {
            isMouseDown = true;
        }, true);
    });

    'mouseup touchend'.split(' ').forEach((eventName) => {
        document.addEventListener(eventName, () => {
            isMouseDown = false;
        }, true);
    });

    'mousemove touchmove'.split(' ').forEach((eventName) => {
        document.addEventListener(eventName, (event) => {
            if (isMouseDown) {
                if (eventName === 'touchmove') {
                    let x = event.touches[0].clientX;
                    x -= sliderElement.getBoundingClientRect().left;
                    mousePosition = {x};
                } else if (eventName === 'mousemove') {
                    mousePosition = {x: event.layerX};
                }

                const left = mousePosition.x - (thumb.offsetWidth / 2);
                const minLeft = 0;

                const leftInPercentage = left / maxLeft;
                const value = (input.max * leftInPercentage.toFixed(2)).toFixed(0);

                if (left >= minLeft && left <= (maxLeft)) {
                    if ((value % 100) === 0) {
                        thumb.style.left = `${left}px`;
                    }
                } else if (left <= minLeft) {
                    isMouseDown = false;
                    thumb.style.left = `${minLeft}px`;
                } else if (left > (maxLeft)) {
                    isMouseDown = false;
                    thumb.style.left = `${maxLeft}px`;
                }

                changeInputValue(input, thumb, slide, maxLeft, callback);
            }
        }, true);
    });

    input.addEventListener('change', (event) => {
        callback(event.target.value);
    }, false);
}
