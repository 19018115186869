export default () => {
    const slider = document.querySelectorAll('.js-client-logos-block');

    if (!slider.length || !window.Swiper) {
        return;
    }

    slider.forEach((sliderElement) => {
        // eslint-disable-next-line no-unused-vars,no-undef
        const swiper = new Swiper(sliderElement, {
            watchSlidesProgress: true,
            keyboardControl: true,
            watchOverflow: true,
            loop: false,
            slidesPerView: 2,
            spaceBetween: 16,
            breakpoints: {
                768: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
            navigation: {
                nextEl: '.js-client-logos-block-navigation-right',
                prevEl: '.js-client-logos-block-navigation-left',
            },
        });
    });
};
