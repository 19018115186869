export default function copyUrlInClipboard() {
    const button = document.querySelector('.js-copy-url-into-clipboard');

    if (button) {
        button.addEventListener('click', () => {
            const inputc = document.body.appendChild(document.createElement('input'));
            inputc.value = window.location.href;
            inputc.focus();
            inputc.select();
            document.execCommand('copy');
            inputc.parentNode.removeChild(inputc);

            alert('URL kopiert.');// eslint-disable-line no-alert
        });
    }
}
