import {getParentElementByClassName} from '../services/traversingService';

export default function handleHeader() {
    /**
     * Toggle für das Hauptmenü
     */
    const toggleButton = document.querySelector('.js-navtoggle');
    const header = document.querySelector('.js-header');
    if (header && !toggleButton) return;
    const toggleButtonInnerText = toggleButton.querySelector('.js-togglebutton-text');
    const submenus = header.querySelectorAll('.js-main-navigation-submenu');

    const resetMenu = () => {
        submenus.forEach((submenu) => {
            const parent = getParentElementByClassName(submenu, 'js-main-navigation-item');
            if (parent.classList.contains('has-open-submenu')) {
                parent.classList.remove('has-open-submenu');
            }
            submenu.classList.add('is-mobile-hidden');
        });
    };

    toggleButton.addEventListener('click', () => {
        header.classList.toggle('is-open');
        document.body.classList.toggle('scrolllock');

        if (!header.classList.contains('is-open')) {
            resetMenu();
        }

        if (toggleButton.getAttribute('aria-expanded') === 'true') {
            toggleButton.setAttribute('aria-expanded', 'false');
            toggleButtonInnerText.innerHTML = 'Menü öffnen';
        } else {
            toggleButton.setAttribute('aria-expanded', 'true');
            toggleButtonInnerText.innerHTML = 'Menü schließen';
        }
    });

    /**
     * Toggle für die Untermenüs des Hauptmenüs
     */
    const submenuMobileToggles = document.querySelectorAll('.js-main-navigation-mobile-submenu-toggle');

    submenuMobileToggles.forEach((submenuToggle) => {
        submenuToggle.addEventListener('click', (event) => {
            const parent = getParentElementByClassName(event.target, 'js-main-navigation-item');
            const submenu = parent.querySelector('.js-main-navigation-submenu');
            parent.classList.toggle('has-open-submenu');
            submenu.classList.toggle('is-mobile-hidden');
        });
    });

    /**
     * Reset des Mobile Menus bei Resize
     */
    window.addEventListener('resize', () => {
        if (header.classList.contains('is-open')) {
            header.classList.remove('is-open');
            resetMenu();
        }

        if (document.body.classList.contains('scrolllock')) {
            document.body.classList.remove('scrolllock');
        }

        if (toggleButton.getAttribute('aria-expanded') === 'true') {
            toggleButton.setAttribute('aria-expanded', 'false');
            toggleButtonInnerText.innerHTML = 'Menü öffnen';
        }
    });

    /**
     * Hide Header on Scroll Down, Show on Scroll Up
     */
    let lastScroll = 0;

    window.addEventListener('scroll', () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            header.classList.remove('is-sticky');
            return;
        }

        if (currentScroll > lastScroll && !header.classList.contains('is-hidden')) {
            header.classList.remove('is-sticky');
            header.classList.add('is-hidden');
        } else if (currentScroll < lastScroll && header.classList.contains('is-hidden')) {
            setTimeout(() => {
                header.classList.remove('is-hidden');
                header.classList.add('is-sticky');
            }, 500);
        }
        lastScroll = currentScroll;
    });
}
