import validatorService from '../services/validatorService';
import {getSibling} from '../services/traversingService';
import formService from '../services/formService';

class PoolieForm {
    constructor(formElement) {
        this.window = window;
        this.form = formElement;
        this.validator = null;
        this.messageSuccess = this.form.querySelector('.js-poolie-form-success');
        this.messageError = this.form.querySelector('.js-poolie-form-error');
        this.emptyFieldsError = this.form.querySelector('.js-poolie-form-empty-error');
        this.errors = [];

        // TODO: Loader anzeigen bei Submit

        this.updateValidator();
        this.registerEvents();
    }

    static messageTimer(element) {
        const messageElement = element;

        if (!messageElement) return;

        setTimeout(() => {
            PoolieForm.hideMessage(messageElement);
        }, 3000);
    }

    registerEvents() {
        this.form.addEventListener('change', (event) => this.handleFormTouched(event));
        this.form.addEventListener('submit', (event) => this.handleFormSubmit(event));
        this.window.addEventListener('formLogicChanged', () => this.updateValidator());
    }

    updateValidator() {
        if (this.validator) {
            this.validator.pristine = null;
        }

        this.validator = validatorService.init(this.form);
    }

    handleFormTouched(event) {
        const changedField = event.target;

        changedField.classList.add('dirty');

        this.form.classList.add('touched');
        this.errors = this.validator.validateForm([changedField]);
        this.resetErrorMessages(changedField);
        this.renderErrors(changedField);
    }

    handleFormSubmit(event) {
        event.preventDefault();

        this.errors = this.validator.validateForm();
        this.resetErrorMessages();
        this.renderErrors();

        const greTokenInput = this.form.querySelector('.js-poolie-form-token-input');
        const greSiteKey = this.form.dataset.sitekey;

        if (!this.errors.length) {
            if (greTokenInput && greSiteKey) {
                // eslint-disable-next-line
                grecaptcha.ready(() => {
                    // eslint-disable-next-line
                    grecaptcha.execute(
                        greSiteKey, {action: 'submit'},
                    ).then((token) => {
                        if (token) {
                            greTokenInput.value = token;
                            this.submitForm();
                        }
                    });
                });
            } else {
                this.submitForm();
            }
        }
    }

    submitForm() {
        this.form.submit();
        formService.toggleFormState(this.form, true);
    }

    renderErrors(input = null) {
        if (this.errors.length) {
            this.errors.forEach((error) => {
                const inputElement = input || error.input;
                if (!inputElement) return;

                inputElement.classList.add('invalid');

                const errorElement = PoolieForm.getErrorElement(inputElement);
                PoolieForm.showMessage(errorElement);
                [errorElement.textContent] = (error.input === undefined) ? [error] : error.errors;
            });

            PoolieForm.showMessage(this.emptyFieldsError);
        } else {
            PoolieForm.hideMessage(this.emptyFieldsError);
        }
    }

    resetErrorMessages(formElements = this.form) {
        let inputs = formElements;

        if (!Array.isArray(formElements)) {
            inputs = [formElements];
        }

        inputs.forEach((input) => {
            const errorElement = PoolieForm.getErrorElement(input);
            if (!errorElement) return;
            input.classList.remove('invalid');
            PoolieForm.hideMessage(errorElement);
        });
    }

    static showMessage(element) {
        const el = element;
        el.style.display = 'flex';
    }

    static hideMessage(element) {
        const el = element;
        el.style.display = 'none';
    }

    static getErrorElement(input) {
        if (!input) return false;

        let element = input;

        if ([].includes.call(['checkbox', 'file', 'radio'], input.type)) {
            element = input.parentElement;
        }

        return getSibling(element, '.js-error-message');
    }
}

const poolieForm = {
    init() {
        const forms = document.querySelectorAll('.js-poolie-form');

        if (!forms.length) return;

        forms.forEach((formElement) => {
            this.poolieForm = new PoolieForm(formElement);
        });
    },
};

export default poolieForm;
