export default function team() {
    const button = document.querySelector('.js-show-team');
    const container = document.querySelector('.js-team');
    const members = document.querySelectorAll('.js-team-member');

    if (!members.length) {
        return;
    }

    members.forEach((member) => {
        member.addEventListener('mouseover', (event) => {
            const target = event.target.classList.contains('js-team-member')
                ? event.target : event.target.closest('.js-team-member');
            const rect = target.getBoundingClientRect();
            const bg = target.querySelector('.js-team-member-bg');
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            bg.style.setProperty('--top', `${y}px`);
            bg.style.setProperty('--left', `${x}px`);
        });
    });

    if (button && container) {
        button.addEventListener('click', () => {
            container.classList.remove('is-collapsed-on-mobile');
        });
    }
}
