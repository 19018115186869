export default function openVideoOverlay() {
    const buttons = document.querySelectorAll('.js-toggle-overlay-video');
    const overlay = document.querySelector('.js-video-overlay');
    const videoWrapper = document.querySelector('.js-video-wrapper');

    function stopVideo(element) {
        const iframe = element.querySelector('iframe');
        const video = element.querySelector('video');
        if (iframe) {
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
        if (video) {
            video.pause();
        }
    }

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            stopVideo(videoWrapper);
            overlay.classList.toggle('is-hidden');
            document.body.classList.toggle('scrolllock');
        });
    });
}
